import React from "react"
import "swiper/css/bundle"
import "./src/styles/global.css"
import UtmTrackingProvider from "./src/utils/context/UtmTrackingProvider"
import { AuthProvider } from "./src/utils/context/AuthContext"

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer")
  }
}

export const wrapRootElement = ({ element }) => (
  <UtmTrackingProvider>
    <AuthProvider>{element}</AuthProvider>
  </UtmTrackingProvider>
)